<template>
  <div class="body">
      <vue-particles
        color="#fff"
        :particleOpacity="0.7"
        :particlesNumber="60"
        shapeType="triangle"
        :particleSize="4"
        linesColor="#fff"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="2"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
        class="lizi"
      >
      </vue-particles>
      <div class="user-login-main">
          <p class="title">测算小程序管理系统</p>
          <a-form :form="form" @submit="handleSubmit">
          <a-form-item>
            <a-input
              v-decorator="['nickname',{ rules: [{ required: true, message: '输入用户名' }] }]"
              placeholder="输入用户名" allowClear size="large"
            >
            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
                type="password"
              v-decorator="['password',{ rules: [{ required: true, message: '输入密码' }] }]"
              placeholder="输入密码" allowClear size="large"
            >
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>
          <a-form-item>
              <a-button type="primary" html-type="submit" block size="large" :loading="loadding">Login</a-button>
          </a-form-item>
        </a-form>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadding:false,
      form: this.$form.createForm(this, { name: "login" }) 
    };
  },
  mounted() {
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loadding = true;
          let data = new FormData();
          Object.keys(values).forEach(key => {
            data.append(key, values[key]);
          });
          this.$http
            .post("/service/login", data)
            .then(res => {
              //把token放在sessionStorage中
              sessionStorage.setItem('token', res.data.result);
              sessionStorage.setItem('name', values['nickname']);
              this.$router.push({name:'user'});
            })
            .catch(() => {
              //请求失败后的处理函数
              
              this.loadding = false;
            });
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.body{
    width: 100%;
    height: 100vh;
    background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
    background-color: #242e40;
    .user-login-main{
        width: 368px;
        top: 50%;
        left: 50%;
        position: fixed;
        margin-left: -184px;
        margin-top: -230px;
        background-color: white;
        border-radius: 2px;
        padding: 24px;
        box-shadow: 1pt 2px 5px rgba(0, 0, 0, 0.3);
        .title {
            color: rgba(0,0,0,.85);
            font-weight: 500;
            font-size: 26px;
            text-align: center;
        }
    }
    
}

</style>
